import {
  createRef,
  CSSProperties,
  lazy,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import 'emoji-mart/css/emoji-mart.css';
import { ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';
import { SnackbarKey, SnackbarOrigin, SnackbarProvider } from 'notistack';

import ActiveSubscriptionOutlet from 'components/ActiveSubscriptionOutlet';
import Layout from 'components/Layout';
import LoadingIndicator from 'components/LoadingIndicator';
import WorkspaceAdminOutlet from 'components/WorkspaceAdminOutlet';

import { AdminModeContextProvider } from 'lib/AdminModeContext';
import { CanvaContextProvider } from 'lib/CanvaContext';
import { HasuraRoleContextProvider } from 'lib/HasuraRoleContext';
import { HeadOfficeModeContextProvider } from 'lib/HeadOfficeModeContext';
import ListingImageSelectProvider from 'lib/ListingContext/ListingImageSelectProvider';
import { UserContext, UserContextProvider } from 'lib/UserContext';
import { UserDataContextProvider } from 'lib/UserContext/UserDataContext';
import { FeatureFlags } from 'lib/feature-flags';
import { FeatureFlagWorkspaceContext } from 'lib/feature-flags/FeatureFlagWorkspaceContext';
import { initGoogleMaps } from 'lib/google-maps';
import { buildApolloClient } from 'lib/graphql/apollo';
import { initLogdna } from 'lib/logdna';
import { initSuprsend } from 'lib/suprsend';
import SuprSendNotificationsProvider from 'lib/suprsend/SuprSendNotificationsProvider';

import AccountNotificationsPage from 'pages/account/Notifications';
import AdminOutlet from 'pages/admin/AdminOutlet';
import CanvaConnectionPopupPage from 'pages/auth/apps/canva';
import ForgotPasswordPage from 'pages/auth/forgot';
import LoginPage from 'pages/auth/login';
import RegisterPage from 'pages/auth/register';
import ResetPasswordPage from 'pages/auth/reset';
import NotFoundPage from 'pages/errors/404';
import UnsecureProxyPage from 'pages/errors/407';
import SSORedirectSuccess from 'pages/other/SSORedirectSuccess';
import AudiencesPage from 'pages/workspace/Audiences';
import AutomationPage from 'pages/workspace/Automation';
import CampaignsPage from 'pages/workspace/Campaigns';
import CampaignAnalyticsPage from 'pages/workspace/Campaigns/pages/CampaignAnalyticsPage';
import CampaignsCalendarPage from 'pages/workspace/Campaigns/pages/CampaignsCalendarPage';
import ContentFeedPage from 'pages/workspace/Content';
import CreativePage from 'pages/workspace/Creatives';
import DigitalBrochuresPage from 'pages/workspace/Creatives/Brochures';
import CustomerReviewsPage from 'pages/workspace/CustomerReviews';
import DashboardPage from 'pages/workspace/Dashboard';
import LeadsPage from 'pages/workspace/Leads';
import LeadAutoResponderPage from 'pages/workspace/Leads/autoresponder';
import LeadsByFormPage from 'pages/workspace/Leads/formleads';
import LeadGenFormsPage from 'pages/workspace/Leads/forms';
import LinksPage from 'pages/workspace/Links';
import PostsPage from 'pages/workspace/Posts';
import PostsAnalyticsPage from 'pages/workspace/Posts/tabs/PostAnalytics';
import PostCalendar from 'pages/workspace/Posts/tabs/PostCalendar';
import PostsReportPage from 'pages/workspace/Posts/tabs/PostsReport';
import PropertiesPage from 'pages/workspace/Properties';
import QuickStartPage from 'pages/workspace/QuickStart';
import DraftVideosPage from 'pages/workspace/RealShortz/tabs/draftVideos';
import Callback from 'pages/workspace/Store/Callback';
import UpgradeWorkspaceSubscriptionPage from 'pages/workspace/UpgradeSubscription';

import theme from './theme';

const BrandPackCreateEditTemplateFromCanvaPage = lazy(
  () => import('components/customTemplates/common/BrandPackCreateEditTemplateFromCanvaPage')
);
const BrandPackCreateNewTemplateFromCanvaPage = lazy(
  () => import('components/customTemplates/common/BrandPackCreateNewTemplateFromCanvaPage')
);
const BrandPackCreateNewTemplatePage = lazy(
  () => import('components/customTemplates/common/BrandPackCreateNewTemplatePage')
);

// Head Office Router
const HeadOfficeRouter = lazy(() => import('pages/headoffice/Router'));

// Other Pages lazy load these ones
const ProfilePage = lazy(() => import('pages/account/Profile'));
const UserProfile = lazy(() => import('pages/workspace/Team/Upsert'));
const MyRealShortzPage = lazy(() => import('pages/account/RealShortz'));
const ListWorkspacesPage = lazy(() => import('pages/account/Workspaces'));
const ListHeadOfficesPage = lazy(() => import('pages/account/HeadOffices'));

const CreateWorkspacePage = lazy(() => import('pages/workspace/Create'));
const SettingsPage = lazy(() => import('pages/workspace/Settings'));
const BillingPage = lazy(() => import('pages/workspace/Billing'));
const BrandingPage = lazy(() => import('pages/workspace/Branding'));
const CampaignTemplatePage = lazy(() => import('pages/workspace/Campaigns/pages/templates'));
const CreateCampaignTemplatePage = lazy(
  () => import('pages/workspace/Campaigns/pages/templates/CreateCampaignTemplatePage')
);
const CloneCampaignTemplatePage = lazy(
  () => import('pages/workspace/Campaigns/pages/templates/CloneCampaignTemplatePage')
);
const EditCampaignTemplatePage = lazy(
  () => import('pages/workspace/Campaigns/pages/templates/EditCampaignTemplatePage')
);
const CampaignPromotionsPage = lazy(() => import('pages/workspace/Campaigns/pages/promotions'));
const CreateCampaignPromotionsPage = lazy(
  () => import('pages/workspace/Campaigns/pages/promotions/CreateCampaignPromotionPage')
);
const EditCampaignPromotionsPage = lazy(
  () => import('pages/workspace/Campaigns/pages/promotions/EditCampaignPromotionPage')
);
const CampaignPromotionLeadsPage = lazy(
  () => import('pages/workspace/Campaigns/pages/promotions/CampaignPromotionLeadsPage')
);

const CampaignsSimpleViewPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsSimpleViewPage')
);
const CreativesCreatePage = lazy(() => import('pages/workspace/Creatives/Create'));
const TeamPage = lazy(() => import('pages/workspace/Team'));
const TeamInvitesPage = lazy(() => import('pages/workspace/Team/Invites'));
const TeamFallbackInfoPage = lazy(() => import('pages/workspace/Team/FallbackInfo'));
const TeamMappingsPage = lazy(() => import('pages/workspace/Team/Mappings'));
const AppsPage = lazy(() => import('pages/workspace/Apps'));
const AppsAppPage = lazy(() => import('pages/workspace/Apps/[app]'));
const AudiencesCreatePage = lazy(() => import('pages/workspace/Audiences/Create'));
const AudiencesCreateSourcePage = lazy(() => import('pages/workspace/Audiences/Create/[source]'));
const CreativesCreateTemplatesPage = lazy(
  () => import('pages/workspace/Creatives/Create/[template]')
);
const CreativesEditTemplatesPage = lazy(() => import('pages/workspace/Creatives/Edit/[template]'));
const RealShortzPage = lazy(() => import('pages/workspace/RealShortz/tabs/finalisedVideos'));
const PostsContentSchedulePage = lazy(
  () => import('pages/workspace/Posts/tabs/PostsContentSchedule')
);
// const AssistantPage = lazy(() => import('pages/workspace/Assistant'));

// Properti Admin
const CustomTemplates = lazy(() => import('pages/admin/CustomTemplates'));
const CreateTemplate = lazy(() => import('pages/admin/CustomTemplates/create'));
const EditTemplate = lazy(() => import('pages/admin/CustomTemplates/edit'));
const BrandPacksPage = lazy(() => import('pages/admin/BrandPacks'));
const EditBrandPackPage = lazy(() => import('pages/admin/BrandPacks/edit/EditBrandPackPage'));

const CampaignsViewCampaignPage = lazy(() => import('pages/workspace/Campaigns/View/[id]'));
const CampaignsCreatePage = lazy(() => import('pages/workspace/Campaigns/Create'));
const CampaignsEditPage = lazy(() => import('pages/workspace/Campaigns/Edit'));
const SimpleCampaignApprovalPage = lazy(
  () => import('pages/workspace/Campaigns/SimpleCampaignApproval')
);
const CampaignsAgentSelectPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsAgentSelectPage')
);
const CampaignsAgentViewPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsAgentViewPage')
);
const CampaignSpendReportPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignSpendReportPage')
);

const CampaignBudgetReportPage = lazy(
  () => import('pages/workspace/Campaigns/pages/CampaignsBudgetReportPage')
);
const JoinWorkspacePage = lazy(() => import('pages/workspace/Team/Join'));
const SupportPage = lazy(() => import('pages/support'));
const CreativeViewPage = lazy(() => import('pages/workspace/Creatives/View'));
const CreateAutomationPage = lazy(() => import('pages/workspace/Automation/Create'));
const CreateAutomationRulePage = lazy(() => import('pages/workspace/Automation/Create/Schedule'));
const CreateAutomationTriggerPage = lazy(() => import('pages/workspace/Automation/Create/Trigger'));
const AutomationTriggersInvocationsPage = lazy(
  () => import('pages/workspace/Automation/Triggers/Invocations')
);
const AutomationScheduleInvocationsPage = lazy(
  () => import('pages/workspace/Automation/Schedules/Invocations')
);
const ImportHeadOfficeAutomationPage = lazy(
  () => import('pages/workspace/Automation/Create/HeadOffice')
);

const ViewPropertyPage = lazy(() => import('pages/workspace/Properties/View'));
const LinkedInProfileOAuthCallback = lazy(
  () => import('pages/account/Profile/Apps/linkedin/callback')
);

const NotificationsPage = lazy(() => import('pages/workspace/Notifications'));
const SupportLoginPage = lazy(() => import('pages/auth/support-login'));

const CreateListingPage = lazy(() => import('pages/workspace/Properties/Create'));
const EditListingPage = lazy(() => import('pages/workspace/Properties/Edit'));

// Store
const StoreOutlet = lazy(() => import('pages/workspace/Store/StoreOutlet'));
const Store = lazy(() => import('pages/workspace/Store'));
const PurchaseHistory = lazy(() => import('pages/workspace/Store/History'));
const CreativePurchase = lazy(() => import('pages/workspace/Store/Purchase/Creative'));

const NON_WORKSPACE_ROUTES = [
  '/welcome',
  '/workspaces',
  '/workspaces/create',
  '/profile',
  '/profile/',
  '/join',
  '/support',
  '/headoffices',
  '/head-office',
  '/head-office/',
  '/me/realshortz',
  '/notifications',
  '/sso-redirect'
];

const VALID_HEAD_OFFICE_MODE_ROUTES = [
  '/profile',
  '/profiles/',
  '/support',
  '/headoffices',
  '/head-office',
  '/head-office/',
  '/me/realshortz',
  '/login/oauth/canva',
  '/notifications'
];

const FALLBACK_DIV_STYLE: CSSProperties = {
  minHeight: '100vh',
  backgroundColor: '#fafafa',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

function Fallback() {
  const [showHelpMessage, setShowHelpMessage] = useState<boolean>(false);

  // Show link to login page if stuck on Fallback loader
  useEffect(() => {
    const timeout = setTimeout(() => setShowHelpMessage(true), 6000);
    return () => {
      clearTimeout(timeout);
    };
  }, [setShowHelpMessage]);

  return (
    <div style={FALLBACK_DIV_STYLE}>
      <LoadingIndicator />
      {showHelpMessage && (
        <div>
          <a href="/login">Having issues? Click here to try re-logging in</a>
        </div>
      )}
    </div>
  );
}

interface VariousProvidersProps {
  children: ReactNode;
}

function VariousProviders({ children }: VariousProvidersProps) {
  return (
    <UserDataContextProvider>
      <AdminModeContextProvider>
        <HeadOfficeModeContextProvider>
          <HasuraRoleContextProvider>
            <FeatureFlagWorkspaceContext>
              <SuprSendNotificationsProvider>
                <ListingImageSelectProvider>
                  <CanvaContextProvider>{children}</CanvaContextProvider>
                </ListingImageSelectProvider>
              </SuprSendNotificationsProvider>
            </FeatureFlagWorkspaceContext>
          </HasuraRoleContextProvider>
        </HeadOfficeModeContextProvider>
      </AdminModeContextProvider>
    </UserDataContextProvider>
  );
}

const AuthenticatedRoutes = () => {
  const { signedIn, activeWorkspaceId, authStatusReported, isHeadOfficeMode } =
    useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Hacky solution for apple devices is we force them back to
  // the login page after ~5 seconds of nothing happening
  const [forceReLogin, setForceReLogin] = useState<boolean>(false);

  useEffect(() => {
    if (authStatusReported && !signedIn) {
      return navigate('/login', {
        state: {
          from: location.pathname,
          search: location.search
        }
      });
    }

    // Navigate to workspaces choice if one is not active
    if (authStatusReported && signedIn && (!activeWorkspaceId || activeWorkspaceId === 'null')) {
      const matches = NON_WORKSPACE_ROUTES.findIndex((route) =>
        route.startsWith(location.pathname)
      );
      if (matches === -1) {
        navigate('/workspaces', {
          replace: true
        });
      }
    }
  }, [navigate, authStatusReported, signedIn, activeWorkspaceId, location]);

  // Navigate to head office dashboard if in headoffice mode
  useEffect(() => {
    if (signedIn && isHeadOfficeMode) {
      const matches = VALID_HEAD_OFFICE_MODE_ROUTES.some(
        (route) => route.startsWith(location.pathname) || location.pathname.startsWith(route)
      );

      if (!matches) {
        navigate('/head-office/dashboard', {
          replace: true
        });
      }
    }
  }, [isHeadOfficeMode, signedIn, location, navigate]);

  // Set force login timer
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (!authStatusReported) {
      timeout = setTimeout(() => setForceReLogin(true), 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [setForceReLogin, authStatusReported]);

  // Navigate to login page if forced after timeout
  // This occurs on apple devices when directly navigating to the empty pathname or `/`
  useEffect(() => {
    if (forceReLogin && !authStatusReported) {
      navigate('/login', {
        state: {
          from: location.pathname,
          search: location.search
        }
      });
    }
  }, [forceReLogin, authStatusReported, navigate, location.pathname, location.search]);

  const apolloClient = useMemo(() => {
    if (signedIn) {
      return buildApolloClient();
    }

    return null;
  }, [signedIn]);

  if (!authStatusReported) {
    return <Fallback />;
  }

  if (signedIn && apolloClient) {
    return (
      <ApolloProvider client={apolloClient}>
        <VariousProviders>
          <Routes>
            {/* Special paths for full screen interactions. i.e. workspace, campaign creation  */}
            <Route path="/workspaces/create" element={<CreateWorkspacePage />} />
            <Route
              path="/campaigns/create"
              element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.campaigns} />}
            >
              <Route path="/campaigns/create" element={<CampaignsCreatePage />} />
            </Route>
            <Route
              path="/campaigns/simple-campaign-approval/:id"
              element={<SimpleCampaignApprovalPage />}
            />
            <Route path="/automation/create/trigger" element={<CreateAutomationTriggerPage />} />
            <Route path="/automation/create/schedule" element={<CreateAutomationRulePage />} />

            <Route path="/quick-start" element={<QuickStartPage />} />

            {/* App Connection Pages */}
            <Route path="login/oauth/canva" element={<CanvaConnectionPopupPage />} />

            {/* Normal paths with Layout component */}
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<ActiveSubscriptionOutlet />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
              </Route>
              <Route path="home/*" element={<Navigate to="/dashboard" replace />} />

              <Route
                path="posts"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.posts} />}
              >
                <Route path="" element={<PostsPage />} />
                <Route path="calendar" element={<PostCalendar />} />
                <Route path="report" element={<PostsReportPage />} />
                <Route path="analytics" element={<PostsAnalyticsPage />} />
                <Route path="head-office-schedule" element={<PostsContentSchedulePage />} />
              </Route>

              <Route
                path="campaigns"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.campaigns} />}
              >
                <Route path="" element={<CampaignsPage />} />
                <Route path="agents" element={<CampaignsAgentSelectPage />} />
                <Route path="agents/:userId" element={<CampaignsAgentViewPage />} />
                <Route path="view/:id" element={<CampaignsViewCampaignPage />} />
                <Route path="edit/:id" element={<CampaignsEditPage />} />
                <Route path="analytics" element={<CampaignAnalyticsPage />} />
                <Route path="reports">
                  <Route path="monthly-spend" element={<CampaignSpendReportPage />} />
                  <Route path="status-budget" element={<CampaignBudgetReportPage />} />
                </Route>
                <Route path="templates" element={<Outlet />}>
                  <Route path="" element={<CampaignTemplatePage />} />
                  <Route path="new" element={<CreateCampaignTemplatePage />} />
                  <Route path="clone/:key" element={<CloneCampaignTemplatePage />} />
                  <Route path="edit/:key" element={<EditCampaignTemplatePage />} />
                </Route>
                <Route path="promotions" element={<Outlet />}>
                  <Route path="" element={<CampaignPromotionsPage />} />
                  <Route path="leads/:id" element={<CampaignPromotionLeadsPage />} />
                  <Route
                    path="new"
                    element={
                      <ActiveSubscriptionOutlet
                        featureFlagName={FeatureFlags.campaign_promotions}
                      />
                    }
                  >
                    <Route path="" element={<CreateCampaignPromotionsPage />} />
                  </Route>
                  <Route
                    path="edit"
                    element={
                      <ActiveSubscriptionOutlet
                        featureFlagName={FeatureFlags.campaign_promotions}
                      />
                    }
                  >
                    <Route path=":id" element={<EditCampaignPromotionsPage />} />
                  </Route>
                </Route>
                <Route path="calendar" element={<CampaignsCalendarPage />} />
                <Route
                  path="simple"
                  element={
                    <ActiveSubscriptionOutlet featureFlagName={FeatureFlags.simple_campaigns} />
                  }
                >
                  <Route path="" element={<CampaignsSimpleViewPage />} />
                </Route>
              </Route>

              <Route
                path="leads"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.leads} />}
              >
                <Route path="" element={<LeadsPage />} />
                <Route
                  path="forms"
                  element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.lead_gen} />}
                >
                  <Route path="" element={<LeadGenFormsPage />} />
                  <Route path=":id/leads" element={<LeadsByFormPage />} />
                </Route>
                <Route path="autoresponder" element={<LeadAutoResponderPage />} />
              </Route>

              {/* <Route path="assistant" element={<ActiveSubscriptionOutlet />}>
                <Route path="" element={<AssistantPage />} />
              </Route> */}

              <Route
                path="audiences"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.audiences} />}
              >
                <Route path="" element={<AudiencesPage />} />
                <Route path="create" element={<Outlet />}>
                  <Route path="" element={<AudiencesCreatePage />} />
                  <Route path="*" element={<AudiencesCreateSourcePage />} />
                </Route>
              </Route>

              <Route
                path="creative"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.creatives} />}
              >
                <Route path="" element={<CreativePage />} />
                <Route path="brochures" element={<DigitalBrochuresPage />} />
                <Route
                  path="create"
                  element={
                    <ActiveSubscriptionOutlet featureFlagName={FeatureFlags.create_creatives} />
                  }
                >
                  <Route path="" element={<CreativesCreatePage />} />
                  <Route path="*" element={<CreativesCreateTemplatesPage />} />
                </Route>
                <Route path="edit" element={<Outlet />}>
                  <Route path="*" element={<CreativesEditTemplatesPage />} />
                </Route>
                <Route path="view/:id" element={<CreativeViewPage />} />
              </Route>

              <Route
                path="realshortz"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.realshortz} />}
              >
                <Route path="" element={<RealShortzPage />} />
                <Route path="draftVideos" element={<DraftVideosPage />} />
              </Route>

              <Route
                path="content"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.content} />}
              >
                <Route path="" element={<ContentFeedPage />} />
              </Route>

              <Route
                path="automation"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.automations} />}
              >
                <Route path="" element={<AutomationPage />} />
                <Route path="create" element={<CreateAutomationPage />} />
                <Route path="create/head-office" element={<ImportHeadOfficeAutomationPage />} />
                <Route path="triggers" element={<Outlet />}>
                  <Route path=":id/invocations" element={<AutomationTriggersInvocationsPage />} />
                </Route>
                <Route path="schedules" element={<Outlet />}>
                  <Route path=":id/invocations" element={<AutomationScheduleInvocationsPage />} />
                </Route>
              </Route>

              <Route
                path="properties"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.listings} />}
              >
                <Route path="" element={<PropertiesPage />} />
                <Route path="listing/create" element={<CreateListingPage />} />
                <Route path="listing/edit/:id" element={<EditListingPage />} />
                <Route path="view/:id" element={<ViewPropertyPage />} />
              </Route>

              <Route
                path="links"
                element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.short_links} />}
              >
                <Route path="" element={<LinksPage />} />
              </Route>

              <Route
                path="reviews"
                element={
                  <ActiveSubscriptionOutlet featureFlagName={FeatureFlags.customer_reviews} />
                }
              >
                <Route path="" element={<CustomerReviewsPage />} />
              </Route>

              <Route path="store" element={<StoreOutlet />}>
                <Route path="" element={<Store />} />
                <Route path="history" element={<PurchaseHistory />} />
                <Route path="callback" element={<Callback />} />
                <Route path="purchase">
                  <Route path="" element={<Navigate to="/store" replace />} />
                  <Route path="creative" element={<CreativePurchase />} />
                </Route>
              </Route>

              <Route path="upgrade" element={<UpgradeWorkspaceSubscriptionPage />} />

              <Route path="workspaces" element={<ListWorkspacesPage />} />
              <Route path="headoffices" element={<ListHeadOfficesPage />} />
              <Route path="settings" element={<WorkspaceAdminOutlet />}>
                <Route path="" element={<SettingsPage />} />
              </Route>
              <Route path="billing" element={<WorkspaceAdminOutlet />}>
                <Route path="" element={<BillingPage />} />
              </Route>

              <Route path="branding" element={<WorkspaceAdminOutlet />}>
                <Route path="" element={<BrandingPage />} />
                <Route path="templates">
                  <Route path="" element={<BrandingPage />} />
                  <Route path="create">
                    <Route path="" element={<BrandPackCreateNewTemplatePage mode="workspace" />} />
                    <Route
                      path="canva/:templateId"
                      element={<BrandPackCreateNewTemplateFromCanvaPage mode="workspace" />}
                    />
                    <Route
                      path="canva/:canvaTemplateId/edit/:customTemplateId"
                      element={<BrandPackCreateEditTemplateFromCanvaPage mode="workspace" />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="apps" element={<WorkspaceAdminOutlet />}>
                <Route path="" element={<AppsPage />} />
                <Route path="*" element={<AppsAppPage />} />
              </Route>

              <Route path="log" element={<NotificationsPage />} />

              <Route path="team" element={<WorkspaceAdminOutlet />}>
                <Route path="" element={<TeamPage />} />
                <Route path="invites" element={<TeamInvitesPage />} />
                <Route
                  path="mappings"
                  element={<ActiveSubscriptionOutlet featureFlagName={FeatureFlags.listings} />}
                >
                  <Route path="" element={<TeamMappingsPage />} />
                </Route>
                <Route path="info" element={<TeamFallbackInfoPage />} />
              </Route>

              {/* Adding team members */}
              <Route path="edit-account/:user_id" element={<UserProfile />} />
              <Route path="create-account" element={<UserProfile />} />

              <Route path="join" element={<JoinWorkspacePage />} />
              <Route path="profile" element={<Outlet />}>
                <Route path="" element={<ProfilePage />} />
                <Route path="apps" element={<Outlet />}>
                  <Route path="linkedin" element={<Outlet />}>
                    <Route path="callback" element={<LinkedInProfileOAuthCallback />} />
                  </Route>
                </Route>
              </Route>

              <Route path="notifications" element={<AccountNotificationsPage />} />

              <Route path="me" element={<Outlet />}>
                <Route path="realshortz" element={<MyRealShortzPage />} />
              </Route>

              <Route path="support" element={<SupportPage />} />

              {/* Head Office Routes */}
              <Route path="head-office/*" element={<HeadOfficeRouter />} />

              {/* Properti Admin Routes */}
              <Route path="admin" element={<AdminOutlet />}>
                <Route path="custom-templates">
                  <Route path="" element={<CustomTemplates />} />
                  <Route path="add" element={<CreateTemplate />} />
                  <Route path="edit/:id" element={<EditTemplate />} />
                </Route>
                <Route path="brand-packs">
                  <Route path="" element={<BrandPacksPage />} />
                  <Route path="edit/:id" element={<EditBrandPackPage />} />
                </Route>
              </Route>

              {/* SSO Login Redirection handler */}
              <Route path="sso-redirect" element={<SSORedirectSuccess />} />

              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </VariousProviders>
      </ApolloProvider>
    );
  }

  // Fallback
  return <Fallback />;
};

const PropertiRoutes = () => {
  // Disable anyone being able to embed our app in an iFrame
  if (window.self !== window.top) {
    return <UnsecureProxyPage />;
  }

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="login-as-support" element={<SupportLoginPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="*" element={<AuthenticatedRoutes />} />
    </Routes>
  );
};

const SNACKBAR_MAX_SNACK = 5;
const SNACKBAR_ANCHOR_ORIGIN: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'bottom'
};

const CONFIRM_PROVIDER_DEFAULT_OPTIONS: ConfirmOptions = {
  confirmationButtonProps: { autoFocus: true }
};

const STRIPE_ELEMENTS_OPTIONS: StripeElementsOptions = {
  mode: 'setup',
  currency: 'aud'
};

const App = () => {
  useEffect(() => {
    initSuprsend();
    initLogdna();
    initGoogleMaps();
  }, []);
  const stripePromise = useMemo(() => {
    return loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
  }, []);

  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = useCallback(
    (key: SnackbarKey) => () => {
      notistackRef?.current?.closeSnackbar(key);
    },
    [notistackRef]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserContextProvider>
        <Elements stripe={stripePromise} options={STRIPE_ELEMENTS_OPTIONS}>
          <BrowserRouter>
            <SnackbarProvider
              maxSnack={SNACKBAR_MAX_SNACK}
              anchorOrigin={SNACKBAR_ANCHOR_ORIGIN}
              ref={notistackRef}
              action={(key) => (
                <IconButton color="inherit" onClick={onClickDismiss(key)}>
                  <CloseIcon color="inherit" />
                </IconButton>
              )}
            >
              <ConfirmProvider defaultOptions={CONFIRM_PROVIDER_DEFAULT_OPTIONS}>
                <DndProvider backend={HTML5Backend}>
                  <Suspense fallback={<Fallback />}>
                    <PropertiRoutes />
                  </Suspense>
                </DndProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </Elements>
      </UserContextProvider>
    </ThemeProvider>
  );
};

export default App;
