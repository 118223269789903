import { useMemo } from 'react';

import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/CopyAll';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupIcon from '@material-ui/icons/Group';

import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  BrochuresQuery,
  useCreateCanvaDesignReturnRedirectUrlMutation,
  useDeleteCreativeMutation,
  Workspace_Template_Engine_Providers_Enum
} from 'generated/graphql';

import CanvaIcon from 'components/icons/CanvaIcon';
import AssignAgentsAssetDialog from 'components/workflows/AssignAgentsAssetDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import useAssignAgentsAsset from 'lib/hooks/useAssignAgentsAsset';
import useUserContext from 'lib/hooks/useUserContext';

import { Tag } from '../TagHolder';

interface BrochureCardProps {
  id: string;
  title: string;
  date: string;
  tags?: Tag[];
  createdByUserId?: string | null;
  brochure: BrochuresQuery['creatives'][0]['brochure'];
  onRefetchRequired: () => void;
}

export default function BrochureCard({
  id,
  title,
  date,
  createdByUserId,
  brochure,
  onRefetchRequired
}: BrochureCardProps) {
  const { userId, activeWorkspaceId, isWorkspaceAdmin } = useUserContext();
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [, copyToClipboard] = useCopyToClipboard();

  const currentWorkspaceUrn = useMemo(() => `workspace:${activeWorkspaceId}`, [activeWorkspaceId]);

  const [createCanvaDesignRedirectUrl] = useCreateCanvaDesignReturnRedirectUrlMutation({
    context: workspaceMemberContext
  });
  const [deleteBrochure] = useDeleteCreativeMutation({
    context: workspaceMemberContext
  });

  const {
    assignAgentAssetDialogOpen,
    handleOpenAssignAgentsAssetDialog,
    handleCloseAssignAgentsAssetDialog
  } = useAssignAgentsAsset();

  const mainImageUrl = `https://res.cloudinary.com/properti/image/upload/h_420,c_thumb/${brochure?.cloudinary_public_id}.jpg`;

  // Advanced images may have images rendered by different images.
  // Lets only enable return navigation on full Canva engine images.
  const isCanvaEditingAvailable =
    brochure?.engine === Workspace_Template_Engine_Providers_Enum.Canva &&
    // Workspace must be using their own connection, not the head office connection
    brochure.engine_owner_urn === currentWorkspaceUrn &&
    brochure.engine_asset_id;

  const handleCanvaReturnNavigation = async () => {
    if (!isCanvaEditingAvailable) {
      enqueueSnackbar('Canva editing not available on this creative', { variant: 'error' });
    }

    try {
      const { data } = await createCanvaDesignRedirectUrl({
        variables: {
          args: { creative_id: id }
        }
      });
      if (data?.createCanvaDesignReturnNavigationRedirectUrl?.design_redirect_url) {
        window.location.href =
          data?.createCanvaDesignReturnNavigationRedirectUrl?.design_redirect_url;
      } else {
        throw new Error('Unable to build edit URL');
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message ?? 'Unable to build Canva edit URL, please try again', {
        variant: 'error'
      });
    }
  };

  const handleDeleteBrochure = async () => {
    try {
      await confirm({
        title: 'Delete brochure',
        description: 'Do you wish to continue? This cannot be undone.'
      });
    } catch (error) {
      // Noop
      return;
    }

    try {
      await deleteBrochure({
        variables: {
          id: id
        }
      });
      onRefetchRequired();
      enqueueSnackbar('Brochure deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to delete brochure', { variant: 'error' });
    }
  };

  const handleCopyLink = () => {
    let shortLink = brochure?.short_link?.short_url ?? brochure?.asset_url!;
    if (!shortLink.startsWith('http')) {
      shortLink = `https://${shortLink}`;
    }
    copyToClipboard(shortLink);
    enqueueSnackbar('Link copied to clipboard!', { variant: 'success' });
  };

  if (!brochure) {
    return null;
  }

  return (
    <>
      <Card elevation={2}>
        <CardActionArea href={brochure!.asset_url} target="_blank">
          <CardMedia
            component="img"
            alt="Brochure thumbnail"
            height={420}
            style={{ objectFit: 'contain' }}
            title={title}
            image={mainImageUrl}
          />
        </CardActionArea>
        <CardContent>
          <Typography variant="overline">{date}</Typography>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="caption">
            {numbro(brochure?.short_link?.rebrandly?.clicks ?? 0).format()} link clicks
          </Typography>
        </CardContent>
        <CardActions>
          <Tooltip title="Copy shareable link">
            <IconButton aria-label="Copy link" onClick={handleCopyLink}>
              <CopyIcon />
            </IconButton>
          </Tooltip>
          {isCanvaEditingAvailable && (
            <Tooltip title="Edit on Canva">
              <IconButton
                aria-label="Edit on Canva"
                disabled={!isCanvaEditingAvailable}
                onClick={handleCanvaReturnNavigation}
              >
                <CanvaIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip style={{ marginLeft: 'auto' }} title="Assign Agents">
            <IconButton
              aria-label="Assign Agents"
              onClick={() => handleOpenAssignAgentsAssetDialog(id)}
              disabled={createdByUserId !== userId && !isWorkspaceAdmin}
            >
              <GroupIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete brochure">
            <IconButton
              aria-label="Delete brochure"
              onClick={handleDeleteBrochure}
              disabled={createdByUserId !== userId && !isWorkspaceAdmin}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <AssignAgentsAssetDialog
        open={assignAgentAssetDialogOpen}
        onClose={handleCloseAssignAgentsAssetDialog}
        assetId={id}
        assetType="creative"
      />
    </>
  );
}
