import React, { useEffect, useState } from 'react';

import IFrame from 'react-iframe';

import { Button, IconButton, LinearProgress, MenuItem, Select } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';

import {
  FacebookAdFormat,
  useFacebookAdPreviewQuery,
  useSavedLeadGenFormQuery
} from 'generated/graphql';

import LeadGenListPreviewDialog from 'components/leads/forms/LeadGenListPreviewDialog';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { APP_CONFIG } from 'lib/app/config';

import { usePreviewStyles } from '../styles';

interface PreviewProps {
  posts?: string[];
  pageId?: string;
  creative_ids?: string[];
  video_ids?: string[];
  objective?: string;
  forceRefreshCount?: number;
  leadFormId?: string;
}

const Preview: React.FC<PreviewProps> = ({
  posts,
  pageId,
  creative_ids,
  video_ids,
  objective,
  leadFormId,
  forceRefreshCount
}) => {
  const { workspaceMemberContext } = useHasuraRoleContext();

  const classes = usePreviewStyles();
  const [post, setPost] = useState<number>(0);
  const [iFrameLoaded, setIFrameLoaded] = useState<boolean>(false);
  const [adFormat, setAdFormat] = useState<FacebookAdFormat>(FacebookAdFormat.MobileFeedStandard);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [leadPreviewOpen, setLeadPreviewOpen] = useState<boolean>(false);

  const creative_id = creative_ids ? creative_ids[post] : null;
  const post_id = posts ? posts[post] : null;
  const video_id = video_ids ? video_ids[post] : null;
  const { data, loading, error, refetch } = useFacebookAdPreviewQuery({
    variables: {
      post_id: post_id ?? '',
      use_post: Boolean(post_id),
      creative_id: creative_id ?? '',
      use_creative: Boolean(creative_id),
      video_id: video_id ?? '',
      use_realshortz: Boolean(video_id),
      ad_format: adFormat,
      objective: objective,
      page_id: pageId ?? undefined,
      leadgen_form_id: leadFormId
    },
    skip: !post_id && !creative_id && !video_id,
    context: workspaceMemberContext
  });

  const { data: leadFormData } = useSavedLeadGenFormQuery({
    variables: {
      id: leadFormId!
    },
    skip: !leadFormId,
    context: workspaceMemberContext
  });

  const handleOpenLeadPreview = () => {
    const leadFormUrl = leadFormData?.workspace_lead_forms_by_pk?.facebook_form_url;
    if (leadFormUrl) {
      window.open(leadFormUrl, '_blank');
    } else {
      setLeadPreviewOpen(true);
    }
  };
  const handleCloseLeadPreview = () => {
    setLeadPreviewOpen(false);
  };

  const handleLoadIFrame = () => {
    setIFrameLoaded(true);
  };

  useEffect(() => {
    if (loading && iFrameLoaded) {
      setIFrameLoaded(false);
    }
  }, [loading, iFrameLoaded, setIFrameLoaded]);

  useEffect(() => {
    if (forceRefreshCount) {
      refetch();
      setRefreshCount(forceRefreshCount);
    }
  }, [forceRefreshCount, setRefreshCount, refetch]);

  let count = 0;
  if (posts) {
    count = posts.length;
  } else if (creative_ids) {
    count = creative_ids.length;
  } else if (video_ids) {
    count = video_ids.length;
  }
  useEffect(() => {
    // If a user deselects an post/creative,
    // ensure the preview is reset to a valid post index
    if (post >= count) {
      setPost(0);
    }
  }, [setPost, post, count]);

  const preview =
    data?.facebook_ad_preview_by_post ??
    data?.facebook_ad_preview_by_creative ??
    data?.facebook_ad_preview_by_realshortz;
  const showLoading = loading || (Boolean(preview) && !iFrameLoaded);

  return (
    <>
      <div>
        <div className={classes.controls}>
          <div>
            <Pagination
              color="primary"
              page={post + 1}
              count={count}
              onChange={(_, page) => setPost(Math.max(0, page - 1))}
              hideNextButton
              hidePrevButton
            />
          </div>
          <div>
            {leadFormId && (
              <Button
                style={{ marginRight: 8 }}
                color="primary"
                variant="text"
                onClick={handleOpenLeadPreview}
              >
                Preview Lead Form
              </Button>
            )}

            <Select
              value={adFormat}
              onChange={(event) => setAdFormat(event?.target?.value as FacebookAdFormat)}
            >
              {Object.values(FacebookAdFormat).map((format) => (
                <MenuItem key={format} value={format}>
                  {format}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              onClick={() => {
                setRefreshCount(refreshCount + 1);
                setIFrameLoaded(false);
              }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
        </div>
        {showLoading && <LinearProgress />}
        {error && <Alert severity="error">Unable to generate previews</Alert>}
        {preview?.map((url, index) => (
          <React.Fragment key={`preview-${url}-${index}-${refreshCount}-${adFormat}`}>
            <IFrame
              className={classes.iframe}
              url={`${url}&ref=${APP_CONFIG.SITE_SLUG}${refreshCount}`}
              width="100%"
              height="800px"
              id={`preview-${index}-${refreshCount}-${adFormat}`}
              scrolling="no"
              loading="lazy"
              onLoad={handleLoadIFrame}
            />
          </React.Fragment>
        ))}
      </div>
      <LeadGenListPreviewDialog
        open={leadPreviewOpen}
        onClose={handleCloseLeadPreview}
        formId={leadFormId}
      />
    </>
  );
};

export default Preview;
