import { makeStyles, Theme } from '@material-ui/core/styles';

const useListItemLinkStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$selected, &$selected:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  selected: {},
  icon: {
    color: 'inherit'
  }
}));

export default useListItemLinkStyles;
