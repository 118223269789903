import React, { useCallback, useState } from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import useListItemLinkStyles from './styles';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  selected: boolean;
  isSubMenu?: boolean;
  onClick?: () => void;
}

function ListItemLink({
  icon,
  primary,
  to,
  selected,
  isSubMenu = false,
  onClick
}: ListItemLinkProps) {
  const classes = useListItemLinkStyles();

  let ListItemComponent = ({ children }: any) => (
    <ListItem
      classes={{ root: classes.root, selected: classes.selected }}
      button
      component={RouterLink}
      to={to}
      selected={selected}
      dense
    >
      {children}
    </ListItem>
  );

  if (onClick) {
    ListItemComponent = ({ children }: any) => (
      <ListItem
        classes={{ root: classes.root, selected: classes.selected }}
        button
        onClick={onClick}
        dense
      >
        {children}
      </ListItem>
    );
  }

  if (to.includes('https://')) {
    ListItemComponent = ({ children }: any) => (
      <ListItem
        classes={{ root: classes.root, selected: classes.selected }}
        button
        component={'a'}
        href={to}
        target="_blank"
        selected={selected}
        dense
      >
        {children}
      </ListItem>
    );
  }

  return (
    <ListItemComponent>
      {icon ? <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon> : null}
      <ListItemText
        inset={isSubMenu}
        primary={primary}
        primaryTypographyProps={{
          color: selected ? 'secondary' : undefined
        }}
      />
    </ListItemComponent>
  );
}

type SubLink = {
  text: string;
  pathname: string;
  shouldRender?: ({
    isWorkspaceAdmin,
    isWorkspaceAdvertiser,
    isWorkspaceAgent
  }: {
    isWorkspaceAdmin: boolean;
    isWorkspaceAdvertiser: boolean;
    isWorkspaceAgent: boolean;
  }) => boolean;
};

interface ListItemLinkNestedProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  selected: boolean;
  subMenu: SubLink[];
}

function ListItemLinkNested({ icon, primary, selected, subMenu }: ListItemLinkNestedProps) {
  const classes = useListItemLinkStyles();
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(selected);

  const handleClick = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  return (
    <>
      <ListItem
        classes={{ root: classes.root, selected: classes.selected }}
        button
        selected={selected}
        onClick={handleClick}
        dense
      >
        {icon ? <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon> : null}
        {primary}
      </ListItem>
      <Collapse in={open && Boolean(subMenu.length)} timeout="auto">
        <List dense>
          {subMenu.map(({ pathname, text }) => {
            const selected =
              location.pathname === pathname || `${location.pathname}${location.hash}` === pathname;

            return (
              <ListItemLink
                isSubMenu
                key={pathname}
                primary={text}
                selected={selected}
                to={pathname}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default ListItemLink;
export { ListItemLinkNested };
