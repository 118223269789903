import React, { useCallback, useEffect, useState } from 'react';

import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

import { Button } from '@material-ui/core';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';

import MaterialTable from '@material-table/core';

import { FORM_ERROR } from 'final-form';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import numbro from 'numbro';

import {
  useCreateShortLinkMutation,
  useDeleteShortLinkMutation,
  useWorkspaceLinksQuery,
  Workspace_Links_Bool_Exp
} from 'generated/graphql';

import AssociatePropertyDialog from 'components/AssociatePropertyDialog';
import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import ShortenLinkModal from 'components/ShortenLinkModal';
import TableContainer from 'components/TableContainer';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

const LinksPage: React.FC = () => {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAnalyst, isWorkspaceAgent } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>();

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [, copyToClipboard] = useCopyToClipboard();

  const [associateLinkId, setAssociateLinkId] = useState<string>();
  const [associateOpen, setAssociateOpen] = useState<boolean>(false);

  const searchFilter = search && search?.length > 0 ? `%${search.replace(/\s+/, '%')}%` : undefined;
  const filters: Workspace_Links_Bool_Exp[] | undefined = searchFilter
    ? [{ target_url: { _ilike: searchFilter } }, { short_url: { _ilike: searchFilter } }]
    : undefined;

  const { data, loading, refetch } = useWorkspaceLinksQuery({
    variables: {
      where: {
        _and: [
          { workspace_id: { _eq: activeWorkspaceId! } },
          { created_by_id: { _is_null: false } },
          {
            _or: isWorkspaceAgent
              ? [
                  { created_by_id: { _eq: userId } },
                  {
                    listings: {
                      listing: {
                        agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
                      }
                    }
                  }
                ]
              : undefined
          },
          { _or: filters }
        ]
      },
      limit: limit,
      offset: page * limit
    },
    context: workspaceMemberContext
  });
  const [createLink] = useCreateShortLinkMutation({ context: workspaceMemberContext });
  const [deleteLink] = useDeleteShortLinkMutation({ context: workspaceMemberContext });

  // Reload when page enters view
  // As we have a SPA, pages enter and leave view without full reloads
  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleOpenAssociateProperty = useCallback(
    (postId: string) => {
      setAssociateLinkId(postId);
      setAssociateOpen(true);
    },
    [setAssociateLinkId, setAssociateOpen]
  );

  const handleCloseAssociateProperty = () => {
    setAssociateOpen(false);
    refetch();
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = async () => {
    setOpen(false);
    await refetch();
  };

  const handleCreateShortLink = async (url: string) => {
    try {
      const resp = await createLink({
        variables: {
          args: {
            workspace_id: activeWorkspaceId!,
            target_url: url
          }
        }
      });
      await refetch();
      copyToClipboard(`https://${resp.data?.createShortLink?.link?.short_url}`);
      enqueueSnackbar('Link copied to clipboard!', { variant: 'success' });
      handleCloseModal();
    } catch (error: any) {
      return {
        [FORM_ERROR]: error?.message ?? 'Unknown Error'
      };
    }
  };

  const handleCopyLink = useCallback(
    (_event: any, row: any) => {
      copyToClipboard(`https://${row.short_url}`);
      enqueueSnackbar('Link copied to clipboard!', { variant: 'success' });
    },
    [copyToClipboard, enqueueSnackbar]
  );

  const handleDeleteShortLink = async (_: any, row: any) => {
    try {
      await confirm({
        description: 'Deleting this short link cannot be undone.'
      });
    } catch (error) {
      return;
    }

    try {
      await deleteLink({ variables: { id: row.id } });
      enqueueSnackbar('Short link deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Unable to delete short link', { variant: 'error' });
    } finally {
      refetch();
    }
  };

  const count = data?.links_aggregate?.aggregate?.count;

  if (!loading && !search && count === 0) {
    return (
      <>
        <EmptyStatePage
          title="Short Links"
          text="Easily create short links to capture session analytics."
          icon={<LinkOutlinedIcon color="secondary" style={{ height: 64, width: 64 }} />}
          button={
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isWorkspaceAnalyst}
              onClick={handleOpenModal}
            >
              Create Short Link
            </Button>
          }
        />
        <ShortenLinkModal open={open} onClose={handleCloseModal} onSubmit={handleCreateShortLink} />
      </>
    );
  }

  // https://github.com/mbrn/material-table/issues/1979
  const links = data?.links?.map((l) => ({ ...l })) ?? [];

  return (
    <>
      <div>
        <PageHeader
          title="Short Links"
          rightComponent={
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={isWorkspaceAnalyst}
              style={{ minWidth: 160 }}
              onClick={handleOpenModal}
            >
              Create Short Link
            </Button>
          }
        />
        <MaterialTable
          title="Short Links"
          columns={[
            {
              title: 'Destination',
              field: 'target_url',
              cellStyle: {
                maxWidth: 500,
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              },
              render: (rowData) => {
                if (rowData.brochure) {
                  return `Brochure: ${rowData.brochure?.creative?.title ?? 'Deleted'}`;
                }

                return rowData.target_url;
              }
            },
            {
              title: 'Short Link',
              field: 'short_url',
              render: (rowData) => (
                <Button variant="text" color="primary" onClick={(e) => handleCopyLink(e, rowData)}>
                  {rowData.short_url}
                </Button>
              )
            },
            {
              title: 'Created',
              field: 'created_at',
              render: (rowData) => moment(rowData.created_at).format('Do MMM YYYY')
            },
            {
              title: 'Clicks',
              field: 'rebrandly.clicks',
              render: (rowData) =>
                numbro(rowData.rebrandly?.clicks).format({ thousandSeparated: true }),
              sorting: false
            },
            {
              title: 'Last Click',
              field: 'rebrandly.lastClickAt',
              render: (rowData) => {
                if (rowData.rebrandly?.lastClickAt) {
                  return moment(rowData.rebrandly?.lastClickAt).fromNow();
                }
                return 'Never';
              },
              sorting: false
            }
          ]}
          data={links}
          totalCount={count ?? 0}
          page={page}
          isLoading={loading}
          options={{
            search: true,
            debounceInterval: 500,
            toolbar: true,
            draggable: false,
            showTitle: false,
            sorting: false,
            actionsColumnIndex: -1,
            pageSize: limit
          }}
          actions={[
            {
              icon: 'open_in_new',
              tooltip: 'Visit',
              onClick: (_, rowData: any) => {
                if (window.open) {
                  window.open(`https://${rowData?.short_url}`, '_blank');
                }
              }
            },
            {
              icon: 'file_copy',
              tooltip: 'Copy Link',
              onClick: handleCopyLink
            },
            (data) => ({
              icon: 'home_work',
              tooltip: 'Associate Properties',
              onClick: () => {
                handleOpenAssociateProperty(data.id);
              },
              disabled: isWorkspaceAnalyst
            }),
            {
              icon: 'delete',
              tooltip: 'Delete',
              onClick: handleDeleteShortLink,
              disabled: isWorkspaceAnalyst
            }
          ]}
          onRowsPerPageChange={(rowsPerPage) => setLimit(rowsPerPage)}
          onPageChange={(newPage) => setPage(newPage)}
          onSearchChange={(newSearch) => setSearch(newSearch)}
          components={{
            Container: TableContainer
          }}
        />
      </div>
      <ShortenLinkModal open={open} onClose={handleCloseModal} onSubmit={handleCreateShortLink} />
      <AssociatePropertyDialog
        variant="link"
        open={associateOpen}
        onClose={handleCloseAssociateProperty}
        linkId={associateLinkId as string}
      />
    </>
  );
};

export default LinksPage;
