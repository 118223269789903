import { useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Grid } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Lock from '@material-ui/icons/Lock';
import { Pagination } from '@material-ui/lab';

import moment from 'moment';
import { useDebounce } from 'use-debounce';

import {
  useBrochuresQuery,
  Workspace_Creative_Types_Enum,
  Workspace_Creatives_Available_Bool_Exp,
  Workspace_Resource_Permission_Modes_Enum
} from 'generated/graphql';

import EmptyStatePage from 'components/EmptyStatePage';
import PageHeader from 'components/PageHeader';
import PaginationContainer from 'components/PaginationContainer';
import BrochureCard from 'components/creative/BrochureCard';
import CreativeCard from 'components/creative/CreativeCard';
import SearchInput from 'components/forms/SearchInput';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { FeatureFlags, useIsFeatureFlagEnabled } from 'lib/feature-flags';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import { usePagination } from 'lib/hooks/useFiltersAndPagination';
import useUserContext from 'lib/hooks/useUserContext';

export default function DigitalBrochuresPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, isWorkspaceAnalyst, isWorkspaceAgent, isWorkspaceAdmin } =
    useUserContext();
  const agentPermissions = useAgentPermissions();

  const createCreativeFlagEnabled = useIsFeatureFlagEnabled(FeatureFlags.create_creatives);

  const [search, setSearch] = useState<string>();
  const [searchValue] = useDebounce(search ?? '', 650);
  const { page, setPage, limit, offset } = usePagination(6, 0);

  const filterSearch = searchValue.length > 0 ? `%${searchValue}%` : undefined;
  const where: Workspace_Creatives_Available_Bool_Exp = {
    _and: [
      { workspace_id: { _eq: activeWorkspaceId! } },
      { title: filterSearch ? { _ilike: filterSearch } : undefined },
      { type: { _eq: Workspace_Creative_Types_Enum.Brochure } }
    ]
  };

  if (isWorkspaceAgent) {
    where._and!.push({
      _or: [
        {
          created_by_id: { _eq: userId }
        },
        {
          agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
        },
        {
          listings: {
            listing: {
              agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
            }
          }
        },
        {
          assigned_users: {
            user_id: { _eq: userId }
          }
        },
        {
          head_office_creative: {}
        }
      ]
    });
  }

  const { data, loading, refetch } = useBrochuresQuery({
    variables: {
      offset: offset,
      limit: limit,
      where: where
    },
    context: workspaceMemberContext
  });

  const totalCount = useMemo(
    () => data?.workspace_creatives_aggregate?.aggregate?.count ?? 0,
    [data?.workspace_creatives_aggregate?.aggregate?.count]
  );

  const pageCount = Math.max(1, Math.ceil(totalCount / limit));
  const brochures = useMemo(() => data?.creatives ?? [], [data?.creatives]);

  // Reload when page enters view
  // As we have a SPA, pages enter and leave view without full reloads
  useEffect(() => {
    refetch();
  }, [refetch]);

  if (!loading && totalCount === 0 && searchValue.length === 0) {
    return (
      <EmptyStatePage
        title="Digital brochures"
        text="Generate digital brochures with Properti and Canva!"
        icon={<AssignmentIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          createCreativeFlagEnabled ? (
            <Button
              component={Link}
              to="/creative/create#brochure"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isWorkspaceAnalyst}
              fullWidth
            >
              Create brochure
            </Button>
          ) : (
            <Button
              component={Link}
              to="/upgrade"
              variant="contained"
              size="large"
              disabled={!isWorkspaceAdmin}
              fullWidth
              startIcon={<Lock />}
            >
              Upgrade to unlock creatives
            </Button>
          )
        }
      />
    );
  }

  return (
    <div>
      <PageHeader
        title="Digital brochures"
        rightComponent={
          createCreativeFlagEnabled ? (
            <Button
              component={Link}
              to="/creative/create#brochure"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isWorkspaceAnalyst}
            >
              Create brochure
            </Button>
          ) : (
            <Button
              component={Link}
              to="/upgrade"
              variant="contained"
              size="large"
              disabled={!isWorkspaceAdmin}
              fullWidth
              startIcon={<Lock />}
            >
              Upgrade to unlock creatives
            </Button>
          )
        }
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: 8,
          maxWidth: 600
        }}
      >
        <SearchInput
          fullWidth
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          placeholder="Search brochures"
          variant="outlined"
          onClickClear={() => {
            setSearch('');
            setPage(0);
          }}
          onClickSearch={() => {
            refetch();
          }}
        />
      </Box>

      <Grid container spacing={2}>
        {loading && !brochures.length ? (
          <>
            {Array.from(Array(6).keys()).map((key) => (
              <Grid key={key} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <CreativeCard key={key} />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {brochures.map((b) => (
              <Grid key={b.id} item xs={12} sm={6} md={4} lg={4} xl={4} style={{ paddingTop: 2 }}>
                <BrochureCard
                  id={b.id!}
                  title={b.title!}
                  date={moment(b.available_at ?? b.created_at).fromNow()}
                  brochure={b.brochure!}
                  onRefetchRequired={refetch}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
      <PaginationContainer
        style={{
          marginTop: 16
        }}
      >
        <Pagination
          count={pageCount}
          page={page + 1}
          onChange={(_, page) => {
            setPage(Math.max(0, page - 1));
          }}
          showFirstButton={pageCount >= 5}
          showLastButton={pageCount >= 5}
        />
      </PaginationContainer>
    </div>
  );
}
