/** @jsxImportSource preact/compat */
import Uppy, { UIPlugin, DefaultPluginOptions } from '@uppy/core';

import { Component, h as React } from 'preact';

import { createFileFromUrl } from 'lib/utils/files';

function CanvaIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="#7D2AE7"
      />
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="url(#paint0_radial_1_40)"
      />
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="url(#paint1_radial_1_40)"
      />
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="url(#paint2_radial_1_40)"
      />
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="url(#paint3_radial_1_40)"
      />
      <path
        d="M45.8152 38.5644C45.5511 38.5644 45.3187 38.7874 45.0769 39.2745C42.3458 44.8125 37.6286 48.731 32.17 48.731C25.8582 48.731 21.9495 43.0333 21.9495 35.1619C21.9495 21.8286 29.3785 14.1195 35.9037 14.1195C38.9527 14.1195 40.8148 16.0357 40.8148 19.0849C40.8148 22.7039 38.7587 24.62 38.7587 25.8964C38.7587 26.4693 39.1151 26.8162 39.8218 26.8162C42.661 26.8162 45.9934 23.5538 45.9934 18.9449C45.9934 14.476 42.1039 11.1912 35.5789 11.1912C24.7951 11.1912 15.2113 21.1888 15.2113 35.0218C15.2113 45.7294 21.3257 52.8051 30.7599 52.8051C40.7734 52.8051 46.5634 42.8423 46.5634 39.6086C46.5634 38.8925 46.1972 38.5644 45.8152 38.5644Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1_40"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.3621 56.7242) rotate(-49.4156) scale(49.4986)"
        >
          <stop stopColor="#6420FF" />
          <stop offset="1" stopColor="#6420FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1_40"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.9432 7.27579) rotate(54.7035) scale(55.8188)"
        >
          <stop stopColor="#00C4CC" />
          <stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1_40"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.3621 56.7242) rotate(-45.1954) scale(48.8994 22.4895)"
        >
          <stop stopColor="#6420FF" />
          <stop offset="1" stopColor="#6420FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_1_40"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.1726 8.62316) rotate(66.5198) scale(50.3869 84.4093)"
        >
          <stop stopColor="#00C4CC" stopOpacity="0.725916" />
          <stop offset="0.0001" stopColor="#00C4CC" />
          <stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

const PRESETS = [
  {
    value: 'custom',
    name: 'Custom',
    width: 1080,
    height: 1080
  },
  {
    value: 'square',
    name: 'Square (1080 x 1080px)',
    width: 1080,
    height: 1080
  },
  {
    value: 'story',
    name: 'Story (1080 x 1920px)',
    width: 1080,
    height: 1920
  },
  {
    value: 'fb-post',
    name: 'Facebook post (940 x 788px)',
    width: 940,
    height: 788
  },
  {
    value: 'fb-ad',
    name: 'Facebook ad (1200 x 628px)',
    width: 1200,
    height: 928
  }
];

export default class UppyCanvaPlugin extends UIPlugin {
  static VERSION = '1.0.0';

  title: string;
  icon: () => JSX.Element;
  opts: any;

  width: number;
  height: number;
  preset: string;

  constructor(uppy: Uppy, opts: DefaultPluginOptions = {}) {
    super(uppy, opts);
    this.id = opts.id || 'Canva';
    this.title = opts.title || 'Canva';
    this.type = 'acquirer';

    this.width = opts.width || 1080;
    this.height = opts.height || 1080;
    this.preset = opts.preset || 'custom';

    const defaultOptions = {};

    this.opts = { ...defaultOptions, ...opts };

    this.icon = () => <CanvaIcon />;

    // Bind `this` to class methods
    this.addFile = this.addFile.bind(this);
    this.render = this.render.bind(this);
  }

  addFile(file: File) {
    try {
      this.uppy.addFile({
        source: this.id,
        name: file.name,
        type: file.type,
        data: file,
        meta: {
          relativePath: null
        }
      });
    } catch (err: any) {
      this.uppy.log(err);
      this.uppy.info({ message: 'Cannot Add File', details: err }, 'error');
    }
  }

  render() {
    const handleInputStateChange = (state: PresetDimensionsInputState) => {
      this.width = state.width;
      this.height = state.height;
      this.preset = state.preset;
    };

    const onClickDesign = () => {
      if ((window as any).CanvaApi) {
        (window as any).CanvaApi.createDesign({
          design: {
            type: 'SocialMedia',
            dimensions: {
              width: this.width,
              height: this.height
            }
          },
          editor: {
            fileType: 'jpeg'
          },
          onDesignPublish: ({ exportUrl, designId }: { exportUrl: string; designId: string }) => {
            createFileFromUrl(exportUrl, `${designId}.jpeg`).then(this.addFile);
          }
        });
      }
    };

    return (
      <div className="uppy-Url">
        <PresetDimensionsInput
          defaultPreset={this.preset}
          defaultHeight={this.height}
          defaultWidth={this.width}
          onChange={handleInputStateChange}
        />

        <button
          className="uppy-u-reset uppy-c-btn uppy-c-btn-primary uppy-Url-importButton"
          style={{ backgroundColor: '#7d2ae8', color: '#fff' }}
          onClick={onClickDesign}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <CanvaIcon />
            <span>Design on Canva</span>
          </span>
        </button>
      </div>
    );
  }

  install() {
    const { target } = this.opts;
    if (target) {
      this.mount(target, this as any);
    }
  }

  uninstaller() {
    this.unmount();
  }
}

interface PresetDimensionsInputProps {
  defaultPreset: string;
  defaultWidth: number;
  defaultHeight: number;
  onChange: (state: PresetDimensionsInputState) => void;
}

interface PresetDimensionsInputState {
  preset: string;
  width: number;
  height: number;
}

class PresetDimensionsInput extends Component<
  PresetDimensionsInputProps,
  PresetDimensionsInputState
> {
  constructor(props: PresetDimensionsInputProps) {
    super(props);
    this.state = {
      preset: props.defaultPreset,
      width: props.defaultWidth,
      height: props.defaultHeight
    };
  }

  componentWillUpdate(
    _nextProps: Readonly<PresetDimensionsInputProps>,
    nextState: Readonly<PresetDimensionsInputState>,
    _nextContext: any
  ): void {
    this.props.onChange(nextState);
  }

  handleChangeNumber = (dimension: 'width' | 'height') => (e: any) => {
    const value = parseInt(e.target.value);
    if (dimension === 'height') {
      this.setState((prevState) => ({ ...prevState, height: value }));
    } else if (dimension === 'width') {
      this.setState((prevState) => ({ ...prevState, width: value }));
    }
  };

  handleChangePreset = (event: any) => {
    const newPresetValue = event.target.value;
    const foundPreset = PRESETS.find((p) => p.value === newPresetValue);

    if (foundPreset && newPresetValue === 'custom') {
      this.setState((prevState) => ({ ...prevState, preset: newPresetValue }));
    } else if (foundPreset) {
      this.setState({
        preset: newPresetValue,
        width: foundPreset.width,
        height: foundPreset.height
      });
    }
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around'
        }}
      >
        <div>
          <label>Preset</label>
          <select
            className="uppy-u-react uppy-c-textInput uppy-Url-input"
            placeholder="Preset"
            value={this.state.preset}
            data-uppy-super-focusable
            onChange={this.handleChangePreset}
            style={{ width: 400, margin: 12 }}
          >
            <option value="custom">Custom</option>
            <option value="square">Square (1080 x 1080px)</option>
            <option value="story">Story (1080 x 1920px)</option>
            <option value="fb-post">Facebook post (940 x 788px)</option>
            <option value="fb-ad">Facebook ad (1200 x 628px)</option>
          </select>
        </div>

        {this.state.preset === 'custom' && (
          <div>
            <label>Width</label>
            <input
              className="uppy-u-reset uppy-c-textInput uppy-Url-input"
              type="number"
              placeholder="Width"
              min={500}
              max={2000}
              step={100}
              value={this.state.width}
              onChange={this.handleChangeNumber('width')}
              data-uppy-super-focusable
              style={{ width: 400, margin: 12 }}
            />
          </div>
        )}

        {this.state.preset === 'custom' && (
          <div>
            <label>Height</label>
            <input
              className="uppy-u-reset uppy-c-textInput uppy-Url-input"
              placeholder="Height"
              type="number"
              value={this.state.height}
              min={500}
              max={2000}
              step={100}
              onChange={this.handleChangeNumber('height')}
              data-uppy-super-focusable
              style={{ width: 400, margin: 12 }}
            />
          </div>
        )}
      </div>
    );
  }
}
