import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import EmptyStatePage from 'components/EmptyStatePage';

import useUserContext from 'lib/hooks/useUserContext';

export default function WorkspaceAdminOutlet() {
  const { loading, isWorkspaceAdmin, isAdminMode } = useUserContext();

  // Let admin users continue
  if (isWorkspaceAdmin || isAdminMode || loading) {
    return <Outlet />;
  }

  return (
    <Box>
      <EmptyStatePage
        title="Workspace admins only"
        text="Only workspace admins can access this page"
        icon={<WarningIcon color="secondary" fill="none" style={{ height: 64, width: 64 }} />}
        button={
          <Button
            component={Link}
            to="/profile"
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
          >
            Go to your profile
          </Button>
        }
      />
    </Box>
  );
}
