import { capitalize } from '@material-ui/core';

import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

export const dynamicParameterSchema = Yup.object({
  dynamic_parameters: Yup.array()
    .of(
      Yup.object({
        function_name: Yup.string().required(),
        identifiers: Yup.array().required(),
        parameters: Yup.array().of(
          Yup.object({
            name: Yup.string().required(),
            value: Yup.mixed()
          })
        )
      })
    )
    .label('Dynamic parameters')
});

export const validateDynamicParameters = makeValidate(dynamicParameterSchema);
export type DynamicParametersSchema = {
  dynamic_parameters: {
    function_name: string;
    identifiers: (string | number)[];
    parameters: {
      name: string;
      value: any;
    }[];
  }[];
};

export function renderDynamicParameterIdentifier(identifiers: (string | number)[]): string {
  if (!identifiers.length) {
    return '';
  }

  const firstIdentifier = identifiers[0];

  // Bannerbear image template
  if (typeof firstIdentifier === 'string') {
    return `Image element identifier: ${firstIdentifier}`;
  }
  // Rocketium video template
  else if (typeof firstIdentifier === 'number') {
    // Scene background image
    if (identifiers.length === 2) {
      return `Scene ${firstIdentifier}, background ${identifiers[1]}`;
    } else {
      return `Scene ${firstIdentifier}, ${identifiers[2]} ${identifiers[1]}`;
    }
  }

  return identifiers.join(', ');
}

/**
 * Renders a dynamic function name (often in snake_case) in human friendly format
 * @param name the function name
 */
export function renderDynamicFunctionName(name: string): string {
  return capitalize(name.replaceAll('_', ' '));
}
