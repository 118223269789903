import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function RealShortzIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 30">
      <path
        d="M23.3396 13.0767L3.35617 0.805125C3.01165 0.593709 2.62808 0.488281 2.24452 0.488281C2.23671 0.488281 2.22903 0.4894 2.22135 0.4894C2.16981 0.489959 2.1184 0.492756 2.06712 0.49709C2.04395 0.499048 2.02091 0.501704 1.99774 0.504501C1.95766 0.509255 1.9177 0.515267 1.87787 0.522399C1.86343 0.525055 1.84885 0.525894 1.8344 0.52883L1.83518 0.529949C1.5862 0.579307 1.3432 0.673549 1.11738 0.814353C0.425736 1.24543 0 2.0395 0 2.89844V27.4415C0 28.3004 0.425736 29.0945 1.11738 29.5256C1.34307 29.6664 1.58607 29.7608 1.83505 29.8101L1.8344 29.8111C1.84742 29.8138 1.86056 29.8146 1.87345 29.8168C1.91627 29.8247 1.95922 29.8311 2.0023 29.836C2.02391 29.8385 2.04538 29.841 2.06699 29.8428C2.11762 29.8472 2.16812 29.8497 2.21888 29.8504C2.22747 29.8505 2.23593 29.8517 2.24452 29.8517C2.62808 29.8517 3.01165 29.7462 3.35617 29.5348L23.3396 17.2631C24.0383 16.834 24.4695 16.0358 24.4695 15.171C24.4695 15.171 24.4695 15.1706 24.4695 15.1705C24.4695 15.1705 24.4695 15.17 24.4695 15.1699C24.4695 15.1699 24.4695 15.1695 24.4695 15.1693C24.4695 15.1693 24.4695 15.1689 24.4695 15.1688C24.4695 14.3041 24.0383 13.506 23.3396 13.0767ZM15.4369 10.6397L13.4499 13.44L8.39472 6.31507L15.4369 10.6397ZM22.3707 15.4421L8.39472 24.0247L17.1455 11.6913L22.3706 14.8999C22.4611 14.9554 22.5173 15.0594 22.5173 15.1709C22.5172 15.2827 22.4609 15.3868 22.3707 15.4421Z"
        fill="#651CFF"
      />
    </SvgIcon>
  );
}

export default RealShortzIcon;
