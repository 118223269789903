import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';

import {
  Avatar,
  Box,
  Divider,
  FormControl,
  Input,
  List,
  ListItemIcon,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AssistantIcon from '@material-ui/icons/AssistantOutlined';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermarkOutlined';
import BrushOutlinedIcon from '@material-ui/icons/BrushOutlined';
import OfficeIcon from '@material-ui/icons/BusinessOutlined';
import SupportIcon from '@material-ui/icons/ContactSupportOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import ScheduleOutlined from '@material-ui/icons/ScheduleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import UpgradeOutlinedIcon from '@material-ui/icons/UpgradeOutlined';

import logdna from '@logdna/browser';

import BullhornIcon from 'mdi-material-ui/BullhornOutline';
import PostIcon from 'mdi-material-ui/PostOutline';
// import RobotIcon from 'mdi-material-ui/RobotExcitedOutline';
import { useSnackbar } from 'notistack';

import {
  useSetActiveWorkspaceMutation,
  useSetAdminActiveWorkspaceMutation,
  Workspace_Subscription_Model_Enum,
  WorkspaceByIdQuery
} from 'generated/graphql';

import LoadingBackdrop from 'components/LoadingBackdrop';
import CanvaIcon from 'components/icons/CanvaIcon';
import RealShortzIcon from 'components/icons/RealShortzIcon';

import { useAdminModeContext } from 'lib/AdminModeContext';
import { useHasuraRoleContext, propertiStaffHasuraContext } from 'lib/HasuraRoleContext';
import { useHeadOfficeModeContext } from 'lib/HeadOfficeModeContext';
import { APP_CONFIG } from 'lib/app/config';
import { auth, getFilePath } from 'lib/auth/hbp';
import { useFeatureFlags, FeatureFlags } from 'lib/feature-flags';
import useBool from 'lib/hooks/useBool';
import useUserContext from 'lib/hooks/useUserContext';

import ListItemLink, { ListItemLinkNested } from '../ListItemLink';
import useSidebarStyles from './styles';

type SubLink = {
  text: string;
  pathname: string;
  featureFlagName?: string;
  shouldRender?: ({
    isWorkspaceAdmin,
    isWorkspaceAdvertiser,
    isWorkspaceAgent
  }: {
    workspace?: WorkspaceByIdQuery['workspace'];
    isWorkspaceAdmin: boolean;
    isWorkspaceAdvertiser: boolean;
    isWorkspaceAgent: boolean;
  }) => boolean;
};

type Link = {
  text: string;
  pathname: string;
  featureFlagName?: string;
  icon: any;
  subMenu?: SubLink[];
  onClick?: () => void;
  shouldRender?: ({
    workspace,
    isAdminMode,
    isHeadOfficeMode,
    isWorkspaceAgent,
    isWorkspaceAdmin
  }: {
    workspace?: WorkspaceByIdQuery['workspace'];
    isAdminMode: boolean;
    isHeadOfficeMode: boolean;
    isWorkspaceAgent: boolean;
    isWorkspaceAdmin: boolean;
  }) => boolean;
};

const mainLinks: Link[] = [
  {
    text: 'Dashboard',
    pathname: '/dashboard',
    icon: DashboardIcon,
    shouldRender: ({ isWorkspaceAgent }) => {
      return !isWorkspaceAgent;
    }
  },
  {
    text: 'Posts',
    pathname: '/posts',
    featureFlagName: FeatureFlags.posts,
    icon: PostIcon,
    subMenu: [
      {
        text: 'Posts',
        pathname: '/posts',
        featureFlagName: FeatureFlags.posts
      },
      {
        text: 'Calendar',
        pathname: '/posts/calendar',
        featureFlagName: FeatureFlags.posts
      },
      {
        text: 'Posts Report',
        pathname: '/posts/report',
        featureFlagName: FeatureFlags.posts
      },
      {
        text: 'Analytics',
        pathname: '/posts/analytics',
        featureFlagName: FeatureFlags.posts
      },
      {
        text: 'Content Schedule',
        pathname: '/posts/head-office-schedule',
        featureFlagName: FeatureFlags.posts,
        shouldRender: ({ workspace, isWorkspaceAdmin }) =>
          isWorkspaceAdmin && Boolean(workspace?.head_offices?.length)
      }
    ]
  },
  {
    text: 'Ad Campaigns',
    pathname: '/campaigns',
    featureFlagName: FeatureFlags.campaigns,
    icon: BullhornIcon,
    subMenu: [
      {
        text: 'All Ad Campaigns',
        pathname: '/campaigns',
        featureFlagName: FeatureFlags.campaigns
      },
      {
        text: 'Simple Ad Campaigns',
        pathname: '/campaigns/simple',
        featureFlagName: FeatureFlags.simple_campaigns
      },
      {
        text: 'Templates',
        pathname: '/campaigns/templates',
        featureFlagName: FeatureFlags.campaigns,
        shouldRender: ({ isWorkspaceAdmin, isWorkspaceAdvertiser, isWorkspaceAgent }) => {
          return isWorkspaceAdmin || isWorkspaceAdvertiser || isWorkspaceAgent;
        }
      },
      {
        text: 'Promotions',
        pathname: '/campaigns/promotions',
        featureFlagName: FeatureFlags.campaigns,
        shouldRender: ({ isWorkspaceAdmin, isWorkspaceAdvertiser, isWorkspaceAgent }) => {
          return isWorkspaceAdmin || isWorkspaceAdvertiser || isWorkspaceAgent;
        }
      },
      {
        text: 'Leadgen Forms',
        featureFlagName: FeatureFlags.lead_gen,
        pathname: '/leads/forms'
      }
    ]
  },
  {
    text: 'Leads',
    pathname: '/leads',
    featureFlagName: FeatureFlags.leads,
    icon: RecentActorsIcon,
    subMenu: [
      {
        text: 'All Leads',
        pathname: '/leads',
        featureFlagName: FeatureFlags.leads
      },
      {
        text: 'Leadgen Forms',
        pathname: '/leads/forms',
        featureFlagName: FeatureFlags.lead_gen
      }
    ]
  },
  // {
  //   text: 'Assistant',
  //   pathname: '/assistant',
  //   icon: RobotIcon
  // },
  {
    text: 'Audiences',
    pathname: '/audiences',
    icon: PersonOutlinedIcon,
    featureFlagName: FeatureFlags.audiences
  },
  {
    text: 'Properties',
    pathname: '/properties',
    featureFlagName: FeatureFlags.listings,
    icon: HomeWorkIcon
  },
  {
    text: 'Creative',
    pathname: '/creative',
    featureFlagName: FeatureFlags.creatives,
    icon: BrushOutlinedIcon,
    subMenu: [
      {
        text: 'Creative',
        pathname: '/creative',
        featureFlagName: FeatureFlags.creatives
      },
      {
        text: 'Brochures',
        pathname: '/creative/brochures',
        featureFlagName: FeatureFlags.creatives
      }
    ]
  },
  {
    text: 'RealShortz',
    pathname: '/realshortz',
    featureFlagName: FeatureFlags.realshortz,
    icon: RealShortzIcon,
    subMenu: [
      {
        text: 'Finalised',
        pathname: '/realshortz',
        featureFlagName: FeatureFlags.realshortz
      },
      {
        text: 'Drafts',
        pathname: '/realshortz/draftVideos',
        featureFlagName: FeatureFlags.realshortz
      }
    ]
  },
  {
    text: 'Canva App',
    pathname: 'https://www.canva.com/your-apps/AAFy_C0B0ig/properti?q=properti',
    featureFlagName: FeatureFlags.canva_app,
    icon: CanvaIcon
  },
  {
    text: 'Reviews',
    pathname: '/reviews',
    featureFlagName: FeatureFlags.customer_reviews,
    icon: RateReviewOutlinedIcon
  },
  {
    text: 'News Content',
    pathname: '/content',
    featureFlagName: FeatureFlags.content,
    icon: RssFeedIcon
  },
  {
    text: 'Short Links',
    pathname: '/links',
    featureFlagName: FeatureFlags.short_links,
    icon: LinkOutlinedIcon
  },
  {
    text: 'Automations',
    pathname: '/automation',
    featureFlagName: FeatureFlags.automations,
    icon: AutoRenewIcon
  },
  {
    text: 'Unlock features',
    pathname: '/upgrade',
    shouldRender: ({ workspace, isWorkspaceAdmin }) => {
      return (
        isWorkspaceAdmin &&
        workspace?.billing_subscription_per_user === false &&
        (workspace?.subscription_model === Workspace_Subscription_Model_Enum.Entry ||
          workspace?.subscription_model === Workspace_Subscription_Model_Enum.Basic)
      );
    },
    icon: UpgradeOutlinedIcon
  }
];

const secondaryLinks: Link[] = [
  {
    text: 'Settings',
    pathname: '/settings',
    icon: SettingsOutlinedIcon
  },
  {
    text: 'Branding',
    pathname: '/branding',
    icon: PaletteOutlinedIcon,
    subMenu: [
      {
        text: 'Branding',
        pathname: '/branding#general'
      },
      {
        text: 'Post Messages',
        pathname: '/branding#messages'
      },
      {
        text: 'Custom Templates',
        pathname: '/branding#templates'
      },
      {
        text: 'Messenger',
        pathname: '/branding#messenger'
      },
      {
        text: 'Fonts',
        pathname: '/branding#fonts'
      },
      {
        text: 'Landing Pages',
        pathname: '/branding#landing'
      }
    ]
  },
  {
    text: 'Billing',
    pathname: '/billing',
    icon: ReceiptOutlinedIcon,
    shouldRender: ({ workspace }) => {
      return Boolean(workspace?.stripe_customer_id);
    }
  },
  {
    text: 'Apps',
    pathname: '/apps',
    icon: AppsIcon,
    subMenu: [
      {
        text: 'All Apps',
        pathname: '/apps#all'
      },
      {
        text: 'Channels',
        pathname: '/apps#channel'
      },
      {
        text: 'Databases & CRMs',
        pathname: '/apps#crm'
      },
      {
        text: 'Notifications',
        pathname: '/apps#notifications'
      },
      {
        text: 'APIs',
        pathname: '/apps#api'
      },
      {
        text: 'Other',
        pathname: '/apps#other'
      }
    ]
  },
  {
    text: 'Team',
    pathname: '/team',
    icon: PeopleOutlinedIcon,
    subMenu: [
      {
        text: 'Team Members',
        pathname: '/team'
      },
      {
        text: 'Invites',
        pathname: '/team/invites'
      },
      {
        text: 'CRM Mappings',
        pathname: '/team/mappings',
        featureFlagName: FeatureFlags.listings
      },
      {
        text: 'Agent Info',
        pathname: '/team/info',
        featureFlagName: FeatureFlags.listings
      }
    ]
  }
];

const supportLinks: Link[] = [
  {
    text: 'Support',
    pathname: '/support',
    icon: SupportIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  }
];

const adminLinks: Link[] = [
  {
    text: 'Custom Templates',
    pathname: '/admin/custom-templates',
    icon: PhotoLibraryIcon,
    shouldRender: ({ isAdminMode, isHeadOfficeMode }) => isAdminMode && !isHeadOfficeMode
  },
  {
    text: 'Brand Packs',
    pathname: '/admin/brand-packs',
    icon: BrandingWatermarkIcon,
    shouldRender: ({ isAdminMode, isHeadOfficeMode }) => isAdminMode && !isHeadOfficeMode
  },
  {
    text: 'Logs',
    pathname: '/log',
    icon: ListAltIcon,
    shouldRender: ({ isAdminMode, isHeadOfficeMode }) => isAdminMode && !isHeadOfficeMode
  }
];

const headOfficeLinks: Link[] = [
  {
    text: 'Dashboard',
    pathname: '/head-office/dashboard',
    icon: AssistantIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Posts',
    pathname: '/head-office/posts',
    icon: PostIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Ad Campaigns',
    pathname: '/head-office/campaigns',
    icon: BullhornIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Analytics',
    pathname: '/head-office/analytics',
    icon: AssessmentOutlinedIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Creative Library',
    pathname: '/head-office/creative',
    icon: BrushOutlinedIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode,
    subMenu: [
      {
        text: 'Media Library',
        pathname: '/head-office/creative'
      },
      {
        text: 'Short Links',
        pathname: '/head-office/creative/short-links'
      }
    ]
  },
  {
    text: 'News Content',
    pathname: '/head-office/content',
    icon: RssFeedIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Content Schedule',
    pathname: '/head-office/schedule',
    icon: ScheduleOutlined,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Offices',
    pathname: '/head-office/offices',
    icon: OfficeIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Brand Pack',
    pathname: '/head-office/brand-pack',
    icon: BrandingWatermarkIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode,
    subMenu: [
      {
        text: 'Brand',
        pathname: '/head-office/brand-pack/brand'
      },
      {
        text: 'Post Messages',
        pathname: '/head-office/brand-pack/messages'
      },
      {
        text: 'Custom Templates',
        pathname: '/head-office/brand-pack/templates'
      },
      {
        text: 'Landing Pages',
        pathname: '/head-office/brand-pack/landing-pages'
      },
      {
        text: 'Automations',
        pathname: '/head-office/brand-pack/automations'
      },
      {
        text: 'Campaign Templates',
        pathname: '/head-office/brand-pack/campaign-templates'
      }
    ]
  },
  {
    text: 'Users',
    pathname: '/head-office/users',
    icon: PeopleOutlinedIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Billing',
    pathname: '/head-office/billing',
    icon: ReceiptOutlinedIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Settings',
    pathname: '/head-office/settings',
    icon: SettingsOutlinedIcon,
    shouldRender: ({ isHeadOfficeMode }) => isHeadOfficeMode
  },
  {
    text: 'Apps',
    pathname: '/head-office/apps',
    icon: AppsIcon
  }
];

const SidebarLinkGroup = ({
  links,
  activeWorkspace
}: {
  links: Link[];
  activeWorkspace?: WorkspaceByIdQuery['workspace'];
}) => {
  const location = useLocation();
  const {
    isWorkspaceAgent = false,
    isWorkspaceAdmin = false,
    isWorkspaceAdvertiser = false,
    isAdminMode
  } = useUserContext();
  const { isHeadOfficeMode } = useHeadOfficeModeContext();
  const { isFlagEnabled } = useFeatureFlags();

  const filteredLinks = useMemo(
    () =>
      links.filter((link) => {
        if (link.featureFlagName) {
          const isEnabled = isFlagEnabled(link.featureFlagName);
          if (!isEnabled) {
            return false;
          }
        }

        if (link.shouldRender) {
          return link.shouldRender({
            workspace: activeWorkspace,
            isAdminMode,
            isHeadOfficeMode,
            isWorkspaceAgent,
            isWorkspaceAdmin
          });
        }
        return true;
      }),
    [
      links,
      activeWorkspace,
      isAdminMode,
      isHeadOfficeMode,
      isWorkspaceAgent,
      isWorkspaceAdmin,
      isFlagEnabled
    ]
  );

  return (
    <>
      {filteredLinks.map(({ text, icon: Icon, pathname, subMenu, onClick }) => {
        const selected = pathname === location.pathname || location.pathname.startsWith(pathname);

        const filteredSubMenu = subMenu?.filter(({ featureFlagName, shouldRender }) => {
          if (featureFlagName) {
            const isEnabled = isFlagEnabled(featureFlagName);
            if (!isEnabled) {
              return false;
            }
          }

          if (shouldRender) {
            return shouldRender({
              workspace: activeWorkspace,
              isWorkspaceAdmin,
              isWorkspaceAdvertiser,
              isWorkspaceAgent
            });
          }

          return true;
        });

        if (filteredSubMenu && filteredSubMenu.length && selected) {
          return (
            <ListItemLinkNested
              key={pathname}
              selected={selected}
              to={pathname}
              primary={text}
              subMenu={filteredSubMenu}
              icon={<Icon />}
            />
          );
        } else {
          return (
            <ListItemLink
              key={pathname}
              selected={selected}
              to={pathname}
              primary={text}
              icon={<Icon />}
              onClick={onClick}
            />
          );
        }
      })}
    </>
  );
};

interface Workspace {
  id: string;
  name: string;
  head_offices: any[];
}

interface HeadOffice {
  id: string;
  name: string;
  brand_pack?: {
    id: string;
    name: string;
    logo_src?: string | null;
    logo_token?: string | null;
  } | null;
}

interface SidebarHeaderProps {
  activeWorkspaceId?: string | null;
  activeWorkspace?: WorkspaceByIdQuery['workspace'];
  availableWorkspaces?: Workspace[];
  activeHeadOfficeId?: string | null;
  activeHeadOffice?: HeadOffice;
  availableHeadOffices?: HeadOffice[];
  onSwitchWorkspace: (id: string) => void;
  onSwitchHeadOffice: (id: string | null) => void;
  isAdminMode: boolean;
  isHeadOfficeMode: boolean;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  activeWorkspaceId,
  activeWorkspace,
  availableWorkspaces,
  activeHeadOffice,
  availableHeadOffices,
  onSwitchWorkspace,
  onSwitchHeadOffice,
  isAdminMode,
  isHeadOfficeMode
}) => {
  const classes = useSidebarStyles();
  const [cachedWorkspaces, setCachedWorkspaces] = useState(availableWorkspaces);
  const [cachedHeadOffices, setCachedHeadOffices] = useState(availableHeadOffices);

  useEffect(() => {
    if (availableWorkspaces && availableWorkspaces !== cachedWorkspaces) {
      setCachedWorkspaces(availableWorkspaces);
    }
  }, [setCachedWorkspaces, availableWorkspaces, cachedWorkspaces]);

  useEffect(() => {
    if (availableHeadOffices && availableHeadOffices !== cachedHeadOffices) {
      setCachedHeadOffices(availableHeadOffices);
    }
  }, [setCachedHeadOffices, availableHeadOffices, cachedHeadOffices]);

  const handleChangeWorkspace = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const value = event.target.value as string;
    if (value) {
      const isWorkspace = cachedWorkspaces?.map((ws) => ws.id).includes(value);
      const isHeadOffice = cachedHeadOffices?.map((ho) => ho.id).includes(value);
      if (isWorkspace) {
        onSwitchWorkspace(value);
      } else if (isHeadOffice) {
        onSwitchHeadOffice(value);
      }
    }
  };

  const selectedWorkspaceId = useMemo(
    () => (cachedWorkspaces ? activeWorkspaceId : ''),
    [cachedWorkspaces, activeWorkspaceId]
  );

  if (cachedWorkspaces && cachedWorkspaces.length === 0 && cachedHeadOffices?.length === 0) {
    return null;
  }

  if (isHeadOfficeMode) {
    const headOfficeLogoUrl = getFilePath(
      activeHeadOffice?.brand_pack?.logo_src,
      activeHeadOffice?.brand_pack?.logo_token
    );

    return (
      <div className={classes.userinfo}>
        <Box sx={{ paddingTop: 12, paddingLeft: 8, textAlign: 'center' }}>
          {headOfficeLogoUrl && (
            <Box
              style={{
                display: 'flex',
                width: '100%',
                placeContent: 'center',
                marginBottom: 8
              }}
            >
              <img src={headOfficeLogoUrl} width="80%" content-fit="contain" alt="" />
            </Box>
          )}
          <Typography variant="h6" gutterBottom>
            {activeHeadOffice?.name}
          </Typography>
        </Box>
      </div>
    );
  }

  if (isAdminMode) {
    return (
      <div className={classes.userinfo}>
        <div>
          <Typography style={{ paddingLeft: 8, paddingTop: 8 }} variant="h4" color="secondary">
            ADMIN MODE: <br />
            {activeWorkspace?.name} <br />
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.userinfo}>
      <FormControl variant="filled" margin="dense">
        <Select
          value={selectedWorkspaceId}
          onChange={handleChangeWorkspace}
          disableUnderline
          input={<Input />}
        >
          {cachedHeadOffices?.length
            ? [
                <MenuItem key="holabel" disabled dense>
                  Your Head Offices
                </MenuItem>,
                cachedHeadOffices?.map((ho) => (
                  <MenuItem key={ho.id} value={ho.id} dense>
                    <ListItemIcon>
                      <Avatar className={classes.avatar} alt={ho.name}>
                        {ho.name
                          ?.match(/\b(\w)/g)
                          ?.slice(0, 2)
                          ?.join('')}
                      </Avatar>
                    </ListItemIcon>
                    {ho.name}
                  </MenuItem>
                ))
              ]
            : null}
          {cachedWorkspaces?.length
            ? [
                <MenuItem key="wslabel" disabled dense>
                  Your Workspaces
                </MenuItem>,
                cachedWorkspaces?.map((aw) => (
                  <MenuItem key={aw.id} value={aw.id} dense>
                    <ListItemIcon>
                      <Avatar className={classes.avatar} alt={aw.name}>
                        {aw.name
                          ?.match(/\b(\w)/g)
                          ?.slice(0, 2)
                          ?.join('')}
                      </Avatar>
                    </ListItemIcon>
                    {aw.name}
                  </MenuItem>
                ))
              ]
            : null}
        </Select>
      </FormControl>
    </div>
  );
};

interface SidebarComponentProps {
  collapsed: boolean;
}

const PERFECT_SCROLLBAR_OPTIONS: ScrollBarProps['options'] = {
  wheelPropagation: false,
  scrollYMarginOffset: 10,
  suppressScrollX: true
};

const FOOTER_IMG_WIDTH = '45%';

const SidebarComponent: React.FC<SidebarComponentProps> = () => {
  const classes = useSidebarStyles();
  const { activeWorkspaceId, isWorkspaceAdmin, info, workspace, userId, headOffices } =
    useUserContext();
  const [setWorkspace] = useSetActiveWorkspaceMutation();
  const [setAdminWorkspace] = useSetAdminActiveWorkspaceMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [backdropLoading, setBackdropLoading, setFinished] = useBool();
  const [backdropTitle, setBackdropTitle] = useState('Switching Workspaces');
  const { isAdminMode } = useAdminModeContext();
  const { isHeadOfficeMode, activeHeadOfficeId, setHeadOfficeMode } = useHeadOfficeModeContext();
  const { meContext } = useHasuraRoleContext();

  const [cachedWorkspace, setCachedWorkspace] = useState<WorkspaceByIdQuery['workspace']>();
  const [cachedHeadOffice, setCachedHeadOffice] = useState<HeadOffice>();

  useEffect(() => {
    if (workspace) {
      setCachedWorkspace(workspace);
    }
  }, [workspace, setCachedWorkspace]);

  useEffect(() => {
    if (activeHeadOfficeId && headOffices) {
      const headOffice = headOffices.find((ho) => ho.id === activeHeadOfficeId);
      setCachedHeadOffice(headOffice);
    }
  }, [setCachedHeadOffice, headOffices, activeHeadOfficeId]);

  const availableWorkspaces: Workspace[] | undefined = useMemo(
    () =>
      info?.workspaces?.map((uw) => ({
        id: uw.workspace.id,
        name: uw.workspace.name,
        head_offices: uw.workspace.head_offices
      })),
    [info?.workspaces]
  );

  const availableHeadOffices: HeadOffice[] | undefined = useMemo(
    () =>
      headOffices?.map((ha) => ({
        id: ha.id,
        name: ha.name
      })),
    [headOffices]
  );

  const handleSwitchWorkspace = useCallback(
    async (workspaceId: string) => {
      if (workspaceId === activeWorkspaceId) {
        return;
      }

      setBackdropTitle(isAdminMode ? 'Switching Admin Workspaces' : 'Switching Workspaces');
      setBackdropLoading();
      try {
        // When in admin mode, you don't want to change the active_workspace_id only the admin_active_workspace_id.
        // When switching admin mode off, we reset the workspace to the active_workspace_id
        const mutation = isAdminMode ? setAdminWorkspace : setWorkspace;

        const resp = await mutation({
          variables: {
            user_id: userId!,
            workspace_id: workspaceId
          },
          context: isAdminMode ? propertiStaffHasuraContext : meContext
        });

        if (resp.errors) {
          throw resp.errors[0];
        }

        // Refresh Auth token to get correct claims
        auth.refreshJWTToken();

        await new Promise((resolve) => setTimeout(resolve, 2500));
      } catch (error: any) {
        logdna.error('Error switching workspace', {
          error
        });
        enqueueSnackbar('Unable to set workspace', { variant: 'error' });
      } finally {
        setFinished();
      }
    },
    [
      activeWorkspaceId,
      setBackdropTitle,
      isAdminMode,
      setBackdropLoading,
      setAdminWorkspace,
      setWorkspace,
      userId,
      meContext,
      enqueueSnackbar,
      setFinished
    ]
  );

  const handleSwitchHeadOffice = useCallback(
    async (headOfficeId: string | null) => {
      setHeadOfficeMode(headOfficeId);
    },
    [setHeadOfficeMode]
  );

  const handleExitHeadOfficeMode = useCallback(() => {
    setHeadOfficeMode(null);
  }, [setHeadOfficeMode]);

  return (
    <>
      <PerfectScrollbar options={PERFECT_SCROLLBAR_OPTIONS}>
        <div className={classes.root}>
          <div className={classes.header}>
            <SidebarHeader
              activeWorkspaceId={activeWorkspaceId}
              activeWorkspace={cachedWorkspace!}
              availableWorkspaces={availableWorkspaces}
              activeHeadOfficeId={activeHeadOfficeId}
              activeHeadOffice={cachedHeadOffice}
              availableHeadOffices={availableHeadOffices}
              onSwitchWorkspace={handleSwitchWorkspace}
              onSwitchHeadOffice={handleSwitchHeadOffice}
              isAdminMode={isAdminMode}
              isHeadOfficeMode={isHeadOfficeMode}
            />
          </div>
          <div className={classes.content}>
            <List>
              {isHeadOfficeMode ? (
                <>
                  <SidebarLinkGroup links={headOfficeLinks} activeWorkspace={cachedWorkspace} />
                </>
              ) : (
                <>
                  <SidebarLinkGroup links={mainLinks} activeWorkspace={cachedWorkspace} />
                  {isWorkspaceAdmin && (
                    <>
                      <Divider className={classes.linkDivider} />
                      <SidebarLinkGroup links={secondaryLinks} activeWorkspace={cachedWorkspace} />
                    </>
                  )}
                </>
              )}

              <Divider className={classes.linkDivider} />
              <SidebarLinkGroup links={supportLinks} activeWorkspace={cachedWorkspace} />
              {isHeadOfficeMode && (
                <>
                  <Divider className={classes.linkDivider} />
                  <ListItemLink
                    key="exit-head-office"
                    selected={false}
                    to="/"
                    onClick={handleExitHeadOfficeMode}
                    icon={<ExitToAppIcon />}
                    primary="Exit Head Office Mode"
                  />
                </>
              )}

              {isAdminMode && (
                <>
                  <Divider className={classes.linkDivider} />
                  <SidebarLinkGroup links={adminLinks} activeWorkspace={cachedWorkspace} />
                </>
              )}
            </List>
          </div>
          <div className={classes.footer}>
            <img
              src={APP_CONFIG.SITE_LOGO_URL_WIDE}
              alt={APP_CONFIG.SITE_TITLE}
              width={FOOTER_IMG_WIDTH}
            />
          </div>
        </div>
      </PerfectScrollbar>
      <LoadingBackdrop title={backdropTitle} open={backdropLoading} />
    </>
  );
};

export default SidebarComponent;
