import { Navigate, Outlet } from 'react-router-dom';

import { useAdminModeContext } from 'lib/AdminModeContext';

export default function AdminOutlet() {
  const { isAdminMode } = useAdminModeContext();

  if (!isAdminMode) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}
